<template>
  <div class="d-flex-column align-center main mw-md">
    <el-card v-if="!profileScreen" class="mt-2 flex-0">
      <div class="mw-311 horizontal-center">
        <div class="flex-1 align-text-center mt-0 mb-1 title">
          {{ current.entity.name }}
        </div>
        <el-button
          type="success"
          class="full-width button-light button-thin"
          @click="$router.push({ name: 'clubsearch' })"
        >
          <span class="grey">Edit</span>
        </el-button>
      </div>
    </el-card>

    <h3 v-if="myProfileList.length !== 0 && !profileScreen" class="mb-0">
      Please select your profile below
    </h3>
    <h3 v-if="myProfileList.length !== 0 && profileScreen" class="mt-0 mb-0">Your Profiles</h3>
    <h3 v-if="myProfileList.length === 0 && !profileScreen" class="mb-0">
      No linked profiles yet. Try creating one?
    </h3>

    <div v-if="!profileScreen" class="clickable">
      <div v-for="person in myProfileList" :key="person.id" class="full-width">
        <Profile
          @profileClicked="chooseProfile(person)"
          :person="person"
          :profileScreen="profileScreen"
          :age="true"
          :noProducts="noProducts(person.id)"
          :notEligible="person.registrationEligibility? (person.registrationEligibility.isNotEligible && current.entity.orgtree.national._id === 32) : false"
        />
      </div>
    </div>
    <div v-else>
      <div v-for="person in myProfileList" :key="person.id" class="full-width">
        <Profile
          @editProfile="edit(person)"
          :person="person"
          :profileScreen="profileScreen"
          :age="false"
        />
      </div>
    </div>
    <div class="align-text-center">
      <div v-if="checkCompleted" class="d-flex-column main loose">
        <div class="align-text-center mw-400">
          <el-button
            type="primary"
            class="w-100 button-fat"
            @click="$router.push({ name: 'regocart' })"
          >
            Complete Registration(s)
          </el-button>
        </div>
      </div>
      <div class="d-flex-column main mb-2 loose">
        <div class="align-text-center mw-400">
          <el-button
            id="newParticipant"
            type="primary"
            class="w-100 button-fat"
            @click="newProfile('new')"
          >
            Add a New Participant
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Profile from "@/components/profile/Profile.vue";
import msg from "@/utils/constants/msg";
import { storeProfile, mapProfile, checkProfileRego } from "@/utils/helpers/members";

export default {
  name: "ChooseProfile",
  components: {
    Profile,
  },
  props: {
    profileScreen: Boolean,
  },
  mounted() {
    this.LOADING(true);
    this.clearOnlyCurrentRego();
    this.$http
      .get("/nrl/api/v1/portal/members")
      .then((membersResponse) => {
        if (
          membersResponse.data &&
          membersResponse.data.status &&
          membersResponse.data.status === "success"
        ) {
          if (membersResponse.data.data) {
            this.myProfileList = membersResponse.data.data.map(
              ({ _id: id, profile, verification, documents, activeRegos, seasonPasses, registrationEligibility}) => ({
                id,
                verification,
                ...profile,
                activeRegos,
                seasonPasses,
                registrationEligibility,
                documents,
                meta: { ...profile.meta },
              })
            );
          }
        } else {
          this.PUSH_NOTIFICATION({
            msg: msg.error.apiError,
            route: this.$route.name,
            type: "warning",
          });
        }
        this.LOADING(false);
      })
      .catch(() => {
        this.PUSH_NOTIFICATION({
          msg: msg.error.apiError,
          route: this.$route.name,
          type: "warning",
        });
        this.LOADING(false);
      });
  },
  methods: {
    ...mapActions("registration", ["updateCurrent", "clearOnlyCurrentRego"]),
    ...mapMutations("views", ["ASK_FOR_NEW_PROFILE_CREATION", "PUSH_NOTIFICATION"]),
    ...mapMutations("root", ["LOADING"]),
    ...mapMutations("registration", ["SET_PRICELIST"]),
    newProfile(type) {
      this.ASK_FOR_NEW_PROFILE_CREATION(type === "existing");
      if (this.profileScreen) {
        this.$router.push({ name: "newprofile" }).catch(() => {});
      } else {
        this.$router.push({ name: "memberform" }).catch(() => {});
      }
    },
    async chooseProfile(person) {
      this.LOADING(true);
      const profileStored = await storeProfile(person);
      const alreadyRegistered = await checkProfileRego(person, this.current.entity);
      if (alreadyRegistered) {
        this.LOADING(false);
        this.PUSH_NOTIFICATION({
          msg: "Our records indicate this participant is already registered to this entity for the current season.",
          route: this.$route.name,
          type: "warning",
        });
      } else if (profileStored) {
        this.LOADING(false);
        this.$router.push({ name: "participantdetails" }).catch(() => {});
      } else {
        this.profilesNoProductList.push(person.id);
        this.LOADING(false);
      }
    },
    edit(person) {
      const profile = mapProfile(person);
      this.updateCurrent({ profile });
      this.$router.push({ name: "editprofile", params: { id: person.id } }).catch(() => {});
    },
    noProducts(id) {
      return this.profilesNoProductList.includes(id);
    },
  },
  data() {
    return {
      theGuy: {},
      myProfileList: [],
      profilesNoProductList: [],
    };
  },
  computed: {
    ...mapState("views", ["askForNewProfileCreation"]),
    ...mapGetters("registration", ["current", "completed", "getAvailableProducts"]),
    checkCompleted() {
      return this.completed.length > 0;
    },
  },
};
</script>

<style lang="scss">
h3 {
  font-family: $fontMedium;
}

.title {
  font-family: $fontMedium, Arial;
}

.grey {
  color: $primary;
  font-size: 0.9em;
}
</style>
